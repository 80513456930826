import { useEffect, useState } from "react";
import PropTypes, { bool, func, object, string } from "prop-types";
import { toast } from "react-toastify";
import moment from "moment";
import { Box, CardContent, CardHeader, Grid, Typography, Link, Card } from "@material-ui/core";

import theme from "globalTheme";
import SimpleInput from "Components/Inputs/SimpleInput";
import TextareaInput from "Components/Inputs/TextareaInput";
import SelectInput from "Components/Inputs/SelectInput";
import BaseButton from "Components/Buttons/BaseButton";
import BaseCheckbox from "Components/Inputs/BaseCheckbox";
import IconSVG from "Components/Icons/IconSVG";
import home from "assets/icons/propriete/home.svg";
import telephone from "assets/icons/propriete/tel.svg";
import EstateTitledSemiUnderlined from "../../EstateTitledSemiUnderlined";
import Map from "Components/Map/Map";
import TelephoneInput from "Components/Inputs/TelephoneInput";
import { formatConcat, isEmailValid, useWindowDimensions } from "helpers/helpers";
import timeIcon from "assets/time-icon.svg";
import Arrow from "Components/Arrow/Arrow";
import WeeklyHoursPopin from "../WeeklyHoursPopin";
import { useContactCardQuery, useSendEstateMailMutation } from "generated/graphql";
import { t, Trans } from "@lingui/macro";
import Loading from "Components/Loader/Loading";
import ToastOptions from "Components/Toasts/ToastOptions";
import { ReCaptchaForm } from "Components/Forms/ReCaptchaForm";
import { useStyles } from "./styles";

// PROPERTY LOCATION
const getPropertyLocationAddress = (address, estateName, styles) => {
    return (
        <Box display="flex">
            <Box display="flex">
                <IconSVG path={home} style={{ width: "20px" }} />
            </Box>
            <Box paddingX="4%">
                <Typography variant="body1" className={styles.textLocation}>
                    {estateName}
                </Typography>
                <Typography variant="body1" className={styles.textLocation}>
                    {formatConcat([address?.address, address?.complementaryAddress])}
                </Typography>
                <Typography variant="body1" className={styles.textLocation}>
                    {formatConcat([address?.postalCode, address?.commune])}
                </Typography>
            </Box>
        </Box>
    );
};

const getPropertyLocationTel = (tel, displayTel, setDisplayTel, styles) => {
    const getTelephones = () => {
        if (tel) {
            return <Typography className={styles.textTel}> {"Tel : " + tel} </Typography>;
        } else {
            return "Téléphone non renseigné";
        }
    };

    const getTelephonesButton = () => {
        if (tel) {
            return (
                <BaseButton
                    disabled={displayTel ? true : false}
                    variant="outlined"
                    onClick={() => setDisplayTel(true)}
                    style={{
                        border: displayTel && "none",
                        paddingRight: "20px",
                        color: theme.palette.common.black,
                        minHeight: "0px",
                        justifyContent: displayTel && "start",
                        paddingLeft: displayTel && 0,
                        backgroundColor: "white",
                    }}
                >
                    {displayTel ? (
                        <>{getTelephones()}</>
                    ) : (
                        <Typography className={styles.textTel}>{t`Afficher le numéro de téléphone`}</Typography>
                    )}
                </BaseButton>
            );
        }
        return null;
    };
    if (tel) {
        return (
            <Box display="flex">
                <Box display="flex" style={{ alignItems: "center" }}>
                    <IconSVG style={{ width: "20px" }} path={telephone} />
                </Box>
                <Box display="flex" paddingX="4%">
                    {getTelephonesButton()}
                </Box>
            </Box>
        );
    }
};

const SubmitButton = ({ disabled, className, isMobileDevice }) => {
    const getButton = () => (
        <BaseButton
            variant="contained"
            color="primary"
            size="small"
            disabled={disabled}
            className={className}
            type="submit"
            fullWidth={isMobileDevice}
        >
            <Trans>Envoyez votre message</Trans>
        </BaseButton>
    );

    return isMobileDevice ? (
        getButton()
    ) : (
        <Box
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
            }}
        >
            {getButton()}
        </Box>
    );
};

SubmitButton.propTypes = {
    disabled: bool,
    className: string,
    fullWidth: bool,
    isMobileDevice: bool,
};

const ContactPropertyFormCard = (props) => {
    const { data, loading, error } = useContactCardQuery({
        variables: { wineEntityId: props.wineEntityId },
    });

    const [sendMail, { data: dataMail, loading: loadingMail, error: errorMail }] = useSendEstateMailMutation();
    const toastOptions = ToastOptions();

    const [displayTel, setDisplayTel] = useState(false);
    const [weeklyHoursPopinOpen, setWeeklyHoursPopinOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [object, setObject] = useState("");
    const [message, setMessage] = useState("");
    const [newsletter, setNewsletter] = useState(false);
    const [confidentiality, setConfidentiality] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const { width } = useWindowDimensions();
    const styles = useStyles(props);

    const { displayOpeningHours, openingHours, estateName, coordinates, address, logo, phoneNumber } =
        data?.ContactCard ?? {};

    const comparedDays = [
        { val: 0, title: t`Dimanche`, titleFr: "Dimanche" },
        { val: 1, title: t`Lundi`, titleFr: "Lundi" },
        { val: 2, title: t`Mardi`, titleFr: "Mardi" },
        { val: 3, title: t`Mercredi`, titleFr: "Mercredi" },
        { val: 4, title: t`Jeudi`, titleFr: "Jeudi" },
        { val: 5, title: t`Vendredi`, titleFr: "Vendredi" },
        { val: 6, title: t`Samedi`, titleFr: "Samedi" },
    ];

    useEffect(() => {
        if (props.setContactAreLoading) {
            props.setContactAreLoading(loading);
        }
    }, [loading]);

    useEffect(() => {
        const isValid = isEmailValid(email) && message?.length >= 14 && confidentiality;
        setIsDisabled(!isValid);
    }, [email, message, confidentiality]);

    useEffect(() => {
        if (dataMail) {
            toast.success(
                toastOptions?.toastSuccessComponent(t`Votre message a bien été envoyé`),
                toastOptions?.saveSuccessOptions
            );
            setTitle("");
            setLastName("");
            setFirstName("");
            setCompany("");
            setPhone("");
            setEmail("");
            setObject("");
            setMessage("");
            setNewsletter(false);
            setConfidentiality(false);
        }
    }, [dataMail]);

    useEffect(() => {
        if (errorMail) {
            toast.error(t`Une erreur empêche l'envoie du mail, veuillez réessayer plus tard`);
        }
    }, [errorMail]);

    const sendMessage = () => {
        sendMail({
            variables: {
                wineEntityId: props.wineEntityId,
                title,
                lastName,
                firstName,
                company,
                telephone: phone,
                emailFrom: email,
                object,
                message: message.replaceAll("\n", "<br>"),
                estateName: estateName || props.estateName,
                newsletter,
            },
        });
    };

    const getContent = (data) => (
        <Box display="flex">
            <Box display="flex">
                <IconSVG path={timeIcon} style={{ width: "20px" }} />
            </Box>
            <Box paddingX="4%">
                <Typography className={styles.textToday}>{t`Aujourd'hui`}</Typography>
                <Typography className={styles.textDataHoraire}>{data} </Typography>
            </Box>
        </Box>
    );

    const formattedTime = (data) => {
        return moment(data)?.format("HH[h]mm");
    };

    const getOpeningTime = (time) => {
        if (time?.openMorning && time?.closeMorning && time?.openAfternoon && time?.closeAfternoon) {
            return getContent(
                `${formattedTime(time?.openMorning)} - ${formattedTime(time?.closeMorning)} / ${formattedTime(
                    time?.openAfternoon
                )} - ${formattedTime(time?.closeAfternoon)}`
            );
        } else if (time?.openMorning && time?.closeMorning && !time?.openAfternoon && !time?.closeAfternoon) {
            return getContent(`${formattedTime(time?.openMorning)} - ${formattedTime(time?.closeMorning)}`);
        } else if (!time?.openMorning && !time?.closeMorning && time?.openAfternoon && time?.closeAfternoon) {
            return getContent(`${formattedTime(time?.openAfternoon)} - ${formattedTime(time?.closeAfternoon)}`);
        } else if (time?.openMorning && !time?.closeMorning && !time?.openAfternoon && time?.closeAfternoon) {
            return getContent(`${formattedTime(time?.openMorning)} - ${formattedTime(time?.closeAfternoon)}`);
        } else {
            return null;
        }
    };

    const getWeeklyHours = () => {
        const todayNumber = moment().day();
        const day = comparedDays?.find((el) => el?.val == todayNumber);
        return openingHours?.map((el, i) => {
            if (el?.name === day?.titleFr) {
                if (el?.open) {
                    return getOpeningTime(el);
                } else {
                    return getContent(t`Fermé`);
                }
            } else {
                return "";
            }
        });
    };

    const getWeeklyHoursPopin = () => (
        <Box display="flex">
            <Box paddingX="13%">
                <Arrow rotation="115" arrowPosition="start">
                    <Link
                        onClick={() => setWeeklyHoursPopinOpen(true)}
                        className={styles.contactPropertyFormCard__linkArrowHorrair}
                    >
                        <Trans>Voir tous les horaires</Trans>
                    </Link>
                </Arrow>
            </Box>
        </Box>
    );

    if (loading || loadingMail) {
        return <Loading />;
    }

    if (!data) {
        return null;
    }

    return (
        <Box className={styles.container_card} style={props.style}>
            {weeklyHoursPopinOpen && (
                <WeeklyHoursPopin
                    modalOpen={weeklyHoursPopinOpen}
                    handleClose={() => setWeeklyHoursPopinOpen(false)}
                    weeklyHours={openingHours}
                    comparedDays={comparedDays}
                />
            )}
            <Card className={[styles.card, props.classNameCard].join(" ")}>
                {!props.isOrphan && !props.restriction && (
                    <CardHeader
                        className={styles.cardHeader}
                        variant="outlined"
                        title={
                            <EstateTitledSemiUnderlined
                                heightAfter={32}
                                title={props.contactSectionTitle || t`Contactez ${estateName || props.estateName}`}
                            />
                        }
                    />
                )}
                <CardContent
                    className={styles.contactPropertyFormCard}
                    style={{ paddingTop: !props.isOrphan && !props.restriction && 0 }}
                >
                    {!props.isOrphan && !props.restriction ? (
                        <Box className={styles.contactPropertyFormCard__wrapper}>
                            <Box className={styles.getPropertyLocation__wrapper}>
                                <Box className={styles.propertyLocation}>
                                    {coordinates && <Map coordinates={coordinates} size={props.size} />}
                                    <Grid container spacing={2} className={styles?.mainContainer}>
                                        {!props.standalone && logo?.data && (
                                            <Box
                                                style={{
                                                    maxWidth: "200px",
                                                    height: "100px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginLeft: theme.spacing(1),
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        maxHeight: "100%",
                                                        maxWidth: "100%",
                                                        margin: "auto",
                                                    }}
                                                    src={logo?.twicpicURL}
                                                    alt={`Logo - ${props.estateName}`}
                                                />
                                            </Box>
                                        )}
                                        {(address?.address && address?.commune && address?.postalCode) ||
                                        phoneNumber ? (
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    {getPropertyLocationAddress(address, estateName, styles)}
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    {getPropertyLocationTel(
                                                        data?.ContactCard?.telephoneNumber,
                                                        displayTel,
                                                        setDisplayTel,
                                                        styles
                                                    )}
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid item sm={12}>
                                                Aucun contact à afficher
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12}>
                                            {displayOpeningHours &&
                                                openingHours &&
                                                openingHours?.length &&
                                                getWeeklyHours()}
                                            {displayOpeningHours &&
                                                openingHours &&
                                                openingHours?.length &&
                                                getWeeklyHoursPopin()}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <ReCaptchaForm
                                onSubmit={() => sendMessage()}
                                submitButton={(challengeCompleted) => (
                                    <SubmitButton
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={isDisabled || loadingMail || !challengeCompleted}
                                        className={styles.formButtonBase}
                                        type="submit"
                                        isMobileDevice={width < 960}
                                    />
                                )}
                                style={{
                                    recaptcha: { gridColumn: "auto / span 6" },
                                    button: { gridColumn: "auto / span 3" },
                                }}
                                className={styles.getFormContact__wrapper}
                            >
                                <Box className={styles.formContact_input01}>
                                    <SelectInput
                                        excluded={[
                                            "Mlle",
                                            "Baron",
                                            "Baronne",
                                            "Ctesse",
                                            "Cte",
                                            "Dr",
                                            "Me",
                                            "Pr",
                                            "S.A.",
                                        ]}
                                        label={t`Titre`}
                                        placeholder={t`Choisir...`}
                                        value={title}
                                        onChange={(e) => setTitle(e)}
                                        enumField="PERSON_SALUTATION"
                                    />
                                </Box>
                                <Box className={styles.formContact_input02}>
                                    <SimpleInput
                                        label={t`Nom`}
                                        value={lastName}
                                        onChange={(e) => setLastName(e?.target?.value)}
                                    />
                                </Box>
                                <Box className={styles.formContact_input03}>
                                    <SimpleInput
                                        label={t`Prénom`}
                                        value={firstName}
                                        onChange={(e) => setFirstName(e?.target?.value)}
                                    />
                                </Box>
                                <Box className={styles.formContact_input04}>
                                    <SimpleInput
                                        label={t`Société`}
                                        value={company}
                                        onChange={(e) => setCompany(e?.target?.value)}
                                    />
                                </Box>
                                <Box className={styles.formContact_input05}>
                                    <TelephoneInput
                                        placeholder={t`Téléphone`}
                                        label={t`Téléphone`}
                                        maxLength={14}
                                        phoneValue={phone}
                                        phoneOnChange={(e) => setPhone(e?.target?.value)}
                                    />
                                </Box>
                                <Box className={styles.formContact_input06}>
                                    <SimpleInput
                                        variant="outlined"
                                        label={t`Adresse e-mail`}
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e?.target?.value)}
                                    />
                                </Box>
                                <Box className={styles.formContact_input07}>
                                    <SimpleInput
                                        label={t`Objet`}
                                        value={object}
                                        onChange={(e) => setObject(e?.target?.value)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box className={styles.formContact_input08}>
                                    <TextareaInput
                                        noTranslation
                                        value={message}
                                        onChange={(value) => setMessage(value)}
                                        label={t`Votre message` + "*"}
                                        textareaMinHeight={200}
                                        minLength={14}
                                        maxLength={1000}
                                    />
                                </Box>
                                <Box className={styles.formContact_input09}>
                                    <Box display="flex" flexDirection="column">
                                        <BaseCheckbox
                                            variant="outlined"
                                            checked={newsletter}
                                            onChange={(e) => setNewsletter(e?.target?.checked)}
                                            style={{ width: "100%" }}
                                            label={
                                                <Box>
                                                    <Typography
                                                        style={{
                                                            display: "inline-block",
                                                            fontFamily: "Lato",
                                                            fontSize: "14px",
                                                            color: "#915868",
                                                        }}
                                                    >
                                                        <Trans>
                                                            Je souhaite recevoir la newsletter de{" "}
                                                            <em>Bordeaux et ses Vins</em>
                                                        </Trans>
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                        <BaseCheckbox
                                            variant="outlined"
                                            labelStyle={{
                                                fontFamily: "Lato",
                                                fontSize: "14px",
                                                color: "#915868",
                                            }}
                                            checked={confidentiality}
                                            onChange={(e) => setConfidentiality(e?.target?.checked)}
                                            label={t`J'ai lu et j'accepte la politique de confidentialité`}
                                            style={{ width: "100%" }}
                                        />
                                    </Box>
                                </Box>
                            </ReCaptchaForm>
                        </Box>
                    ) : (
                        <Box className={styles.contactPropertyFormCard__wrapper}>
                            <Box className={styles.getPropertyLocation__wrapper_orphan}>
                                {coordinates && (
                                    <Box className={styles.containerMapAddress}>
                                        <Box className={styles.mapContainer}>
                                            <Map coordinates={coordinates} size={props.size} />
                                        </Box>
                                    </Box>
                                )}
                                {props.restriction ? (
                                    <Box className={styles.container_credentials}>
                                        {getPropertyLocationAddress(address, estateName, styles)}
                                    </Box>
                                ) : (
                                    <Box className={styles.containerOrphanTitle}>
                                        <Typography variant="h3" style={{ fontWeight: "700" }}>
                                            {estateName} est votre propriété ?
                                        </Typography>
                                        <Typography
                                            className={styles.OrphanSubtitle}
                                            variant="h5"
                                            style={{
                                                textDecoration: "underline",
                                                color: theme.palette.primary.light,
                                                cursor: "pointer",
                                            }}
                                            onClick={() => props.regainControl()}
                                        >
                                            Prenez gratuitement le contrôle sur sa visibilité !
                                        </Typography>
                                        <Box className={styles.arrowOrphanSubtitle}>
                                            <Arrow
                                                rotation={"105"}
                                                arrowPosition="start"
                                                style={{
                                                    cursor: "pointer",
                                                    justifyContent: "center",
                                                }}
                                                mediaStyle={{ marginRight: 0 }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        textDecoration: "underline",
                                                        color: theme.palette.primary.light,
                                                    }}
                                                    onClick={() => props.regainControl()}
                                                >
                                                    Prenez gratuitement le contrôle sur sa visibilité !
                                                </Typography>
                                            </Arrow>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

ContactPropertyFormCard.propTypes = {
    estateName: PropTypes.string,
    address: PropTypes.objectOf(PropTypes.object),
    contact: PropTypes.objectOf(PropTypes.object),
    coordinates: PropTypes.object,
    identityLinked: PropTypes.bool,
    logo: PropTypes.string,
    phoneNumber: PropTypes.string,
    weeklyHours: PropTypes.array,
    wineEntityId: PropTypes.string,
    size: PropTypes.string,
    isOrphan: bool,
    style: object,
    regainControl: func,
    contactSectionTitle: string,
    standalone: bool,
    classNameCard: object,
    restriction: bool,
    setContactAreLoading: func,
};

export default ContactPropertyFormCard;
