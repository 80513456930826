import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Lightbox from "react-image-lightbox";
import { makeStyles, Grid } from "@material-ui/core";
import "react-image-lightbox/style.css";

import { mod, parseHtml } from "../../helpers/helpers";
import subPropImg1 from "../../assets/propriétéPage/propriété_01-féret.png";
import subPropImg2 from "../../assets/propriétéPage/propriété_02_féret.png";
import floriture from "../../assets/fleuritures/estate/no-image.svg";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 0)",
    },
    gradientBox: {
        position: "absolute",
        width: "calc(100% - 8px)",
        height: "calc(100% - 8px)",
        zIndex: 10,
        backgroundColor: "rgba(0,0,0,0.5)",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2px",
    },
    mobilePreviewGradientBox: {
        position: "absolute",
        width: "calc(100% - 1px)",
        height: "calc(100% - 1px)",
        zIndex: 10,
        backgroundColor: "rgba(0,0,0,0.5)",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2px",
    },
    gradientBoxTypo: {
        fontSize: "38px",
        color: "white",
        textAlign: "center",
        fontWieght: "bold",
    },
    mainImg: {
        objectFit: "cover",
        width: "100%",
        "&:hover": {
            cursor: "pointer",
        },
        borderRadius: "2px",
    },
    floritureImg: {
        objectFit: "cover",
        width: "100%",
        borderRadius: "2px",
    },
    fourthImg: {
        width: "100%",
        height: "100%",
        "&:hover": {
            cursor: "pointer",
        },
        borderRadius: "2px",
    },
}));

const LgGallery = (props) => {
    const styles = useStyles();
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [allImagesIndex, setAllImagesIndex] = useState(0);

    let filteredImagesUrls = [];
    let captions =
        props.imageUrls?.length && props.imageUrls.length > 0 ? props.imageUrls.map((el) => el?.caption ?? "") : "";

    if (props.front) {
        filteredImagesUrls = props.imageUrls?.map((el) => el?.twicpicURL);
    } else {
        filteredImagesUrls = [...props.imageUrls];
    }

    const allImages = [...filteredImagesUrls];

    if (props.mainImageUrl) {
        allImages.unshift(props.mainImageUrl);
    }

    const mainImageXsValue = useMemo(() => {
        if (props.imageUrls?.length === 3 && !props.mobilePreview) {
            return 4;
        } else if (props.mobilePreview) {
            return 12;
        } else {
            return 8;
        }
    }, [props.imageUrls, props.mobilePreview]);

    return (
        <Grid
            container
            direction={"row"}
            spacing={0}
            style={{
                width: !props.mobilePreview && "calc(100% + 16px)",
                justifyContent: "space-between",
                marginRight: 8,
                padding: 0,
            }}
        >
            {lightboxIsOpen && (
                <Lightbox
                    imagePadding={100}
                    mainSrc={allImages.length && allImages[allImagesIndex]}
                    onCloseRequest={() => {
                        setLightboxIsOpen(false);
                    }}
                    nextSrc={allImages[mod(allImagesIndex + 1, allImages?.length)]}
                    prevSrc={allImages[mod(allImagesIndex - 1, allImages?.length)]}
                    onMoveNextRequest={() => setAllImagesIndex(mod(allImagesIndex + 1, allImages?.length))}
                    onMovePrevRequest={() => setAllImagesIndex(mod(allImagesIndex - 1, allImages?.length))}
                    imageCaption={parseHtml(captions[allImagesIndex])}
                />
            )}
            {/* Main image */}
            {!!allImages.length && (
                <Grid item xs={mainImageXsValue} style={{ padding: 0, paddingRight: 8 }}>
                    <img
                        className={styles.mainImg}
                        src={allImages[0]}
                        onClick={() => {
                            setLightboxIsOpen(true);
                            setAllImagesIndex(0);
                        }}
                    />
                </Grid>
            )}
            {allImages?.length === 0 && (
                <Grid item xs={mainImageXsValue} style={{ paddingBottom: props.mobilePreview ? 0 : 4 }}>
                    <img className={styles.mainImg} src={subPropImg1} />
                </Grid>
            )}

            <Grid
                item
                xs={4}
                style={{
                    backgroundImage: `url(${floriture})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPositionX: "right",
                    padding: 0,
                    overflow: "hidden",
                }}
            />
        </Grid>
    );
};

LgGallery.propTypes = {
    cols: PropTypes.number,
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    mainImageUrl: PropTypes.string,
    mobilePreview: PropTypes.bool,
    plusStyle: PropTypes.object,
    front: PropTypes.bool,
};

export default LgGallery;
