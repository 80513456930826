import { Backdrop, Box, Icon, makeStyles, Typography } from "@material-ui/core";
import ftImage from "assets/navbarfront/ft.svg";
import journalistImage from "assets/navbarfront/journalistImage.svg";
import { FR_AND_EN, SUBSCRIPTION_TYPES, URL_REDIRECT } from "config/constants";
import BrowsingPreferenceModal from "Front/browsingPreference/BrowsingPreferenceModal";
import { bool, func, object } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import viewEstate from "../../assets/eye-open.svg";
import arrowButton from "../../assets/navbarfront/arrow_button.svg";
import arrow from "../../assets/navbarfront/arrow_search.svg";
import fioriture from "../../assets/navbarfront/fioriture_account.svg";
import logout from "../../assets/navbarfront/logout_front.svg";
import manage from "../../assets/navbarfront/manage_infos.svg";
import BaseButton from "../../Components/Buttons/BaseButton";
import Loading from "../../Components/Loader/Loading";
import { signIn, signOut } from "../../features/user/userSlice";
import theme from "../../globalTheme";
import { formatPerson, getPaymentStatus, menuCTA, urlFormatted, useWindowDimensions } from "../../helpers/helpers";
import ConnectionContainer from "../../SubscriptionTunnel/Containers/ConnectionContainer";
import SubscriptionInProgress from "./SubscriptionInProgress";
import { useCustomHistory } from "hooks";
import { useTranslation } from "react-i18next";
import BaseChip from "Components/Chips/BaseChip";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    container_account: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.card,
        position: "absolute",
        top: "0",
        right: "0",
        width: "436px",
        height: "100vh",
        backgroundImage: `url(${fioriture})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom left",
        [theme.breakpoints.down("sm")]: {
            width: "100vw",
            top: "0",
            left: "0",
            backgroundImage: "unset",
        },
    },
    container_title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        display: "flex",
        padding: "2rem 1rem",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: "1rem 0.5rem",
        },
    },
    backIcon: {
        position: "absolute",
        left: "1rem",
        cursor: "pointer",
    },
    form_account: {
        padding: "2rem",
        [theme.breakpoints.down("md")]: {
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": { width: "4px" },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                paddingRight: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "20px",
            },
        },
    },
    step_text: {
        display: "flex",
        alignItems: "start",
        margin: "0 2rem",
    },
    register_container: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    register: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "center",
        },
    },
    choose_subscription: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            background: theme.palette.common.white,
            padding: "1rem 1rem 2rem 1rem",
            borderRadius: "6px",
            marginTop: "1rem",
        },
    },
    consult_ft: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            background: theme.palette.background.card,
            padding: "0 1rem 2rem 1rem",
            borderRadius: "6px",
            marginTop: "1rem",
            border: `0.4px solid ${theme.palette.secondary.dark}`,
        },
    },
    text_connected: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: "fit-content",
        textDecoration: "none",
    },
    icons_connected: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "20px",
        marginRight: "0.5rem",
    },
}));

const Account = React.forwardRef((props, ref) => {
    const [called, setCalled] = useState(false);
    const paymentStatus = useSelector((state) => getPaymentStatus(state));
    const styles = useStyles();
    const history = useCustomHistory();
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const settingsLocale = useSelector((state) => state?.user?.locale || "fr");
    const [warningBrowserLanguage, setWarningBrowserLanguage] = useState(settingsLocale);
    const user = useSelector((state) => state.user);
    const { currentUser, loading } = user ?? {};
    const { t: ti18next } = useTranslation();

    useEffect(() => {
        if (currentUser?.subscriptionName === SUBSCRIPTION_TYPES.journalist || !currentUser) {
            setWarningBrowserLanguage(!FR_AND_EN.includes(settingsLocale));
        } else {
            setWarningBrowserLanguage(settingsLocale !== "fr");
        }
    }, [settingsLocale]);

    useEffect(() => {
        if (currentUser && called) {
            props.onClose();
            history.push("/dashboard");
        }
    }, [currentUser]);

    const handleConnectionOnSubmit = async (values) => {
        dispatch(
            signIn({
                username: values.subscriptionEmailAddress,
                password: values.subscriptionPassword,
            })
        );
        setCalled(true);
    };

    const handleSignOut = () => {
        dispatch(signOut());
        props.onClose();
    };

    const getSecondPartContent = () => {
        if (props.openConnectionMenu) {
            return (
                <BaseButton size="small" fullWidth color="primary" variant="outlined" style={{ marginTop: "1rem" }}>
                    S&apos;inscrire
                </BaseButton>
            );
        } else {
            return (
                <>
                    <Box className={styles.choose_subscription}>
                        <Typography
                            variant="body2"
                            style={{
                                marginTop: "1rem",
                                textAlign: width <= 300 && "center",
                            }}
                        >
                            Vous êtes une propriété et vous souhaitez figurer dans <i>Bordeaux et ses Vins ?</i>
                        </Typography>
                        {width > 300 ? (
                            <Box
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: width < 960 && "center",
                                }}
                            >
                                <Icon style={{ width: "2rem", marginRight: "0.5rem", height: "100%" }}>
                                    <img src={arrowButton} />
                                </Icon>
                                <BaseButton
                                    component={Link}
                                    to={history.addLocale("/bienvenue/inscription")}
                                    boxStyle={{ flex: width >= 960 && "1" }}
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    fullWidth={width >= 960}
                                    style={{ padding: width < 960 && "4px 68px" }}
                                >
                                    S&apos;inscrire maintenant
                                </BaseButton>
                            </Box>
                        ) : (
                            <BaseButton
                                component={Link}
                                to={history.addLocale("/bienvenue/inscription")}
                                boxStyle={{ width: "100%" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                S&apos;inscrire maintenant
                            </BaseButton>
                        )}
                    </Box>
                    <Box className={styles.consult_ft}>
                        <Typography
                            variant="body2"
                            style={{
                                marginTop: "2rem",
                                textAlign: width <= 300 && "center",
                            }}
                        >
                            Vous souhaitez consulter des fiches techniques ?
                        </Typography>
                        <Box className={styles.step_text}>
                            <Typography variant="h6" style={{ fontFamily: "Mansalva", marginRight: "1rem" }}>
                                1
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{ fontFamily: "Mansalva", textAlign: width <= 300 && "center" }}
                            >
                                Si une propriété souhaite partager avec vous ses fiches, vous recevrez une invitation
                                par e-mail afin de créer votre espace de consultation.
                            </Typography>
                        </Box>
                        <Typography
                            variant="h6"
                            style={{
                                textAlign: "center",
                                color: theme.palette.secondary.dark,
                                margin: "0.5rem 0",
                                fontWeight: 700,
                            }}
                        >
                            OU
                        </Typography>
                        <Box className={styles.step_text}>
                            <Typography variant="h6" style={{ fontFamily: "Mansalva", marginRight: "1rem" }}>
                                2
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{ fontFamily: "Mansalva", textAlign: width <= 300 && "center" }}
                            >
                                Rendez-vous directement sur la page Féret d’un millésime, cliquez sur “télécharger la
                                fiche technique” et laissez-vous guider.
                            </Typography>
                        </Box>
                    </Box>
                </>
            );
        }
    };

    if (width < 960 && props.currentUser) {
        return (
            <>
                {/* if not connected */}
                <BrowsingPreferenceModal
                    noEnlish={currentUser && currentUser.subscriptionName !== SUBSCRIPTION_TYPES.journalist}
                    registrationMode={!currentUser}
                    open={warningBrowserLanguage}
                    onClickBack={() => {
                        setWarningBrowserLanguage(false);
                        props.onClose();
                    }}
                    onClose={() => {
                        setWarningBrowserLanguage(false);
                        props.onClose();
                    }}
                />
                <Backdrop open={true} style={{ zIndex: "1" }}>
                    <Box className={styles.container_account} ref={ref} style={{ height: "auto" }}>
                        <Box className={styles.container_title}>
                            <Icon className={styles.backIcon} onClick={() => props.onClose()}>
                                <img src={arrow} />
                            </Icon>
                            <Typography variant="h4">
                                {ti18next("common.hello")}{" "}
                                {props.currentUser?.wineEntityName ?? formatPerson(props.currentUser)}
                            </Typography>
                        </Box>
                        <Box style={{ padding: "1rem 2rem" }}>
                            {props.currentUser?.wineEntityName ? (
                                <Box>
                                    <Link className={styles.text_connected} to="/dashboard/estate/general">
                                        <Icon className={styles.icons_connected}>
                                            <img style={{ width: "100%", height: "100%" }} src={manage} />
                                        </Icon>
                                        <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
                                            Gérer mes informations
                                        </Typography>
                                    </Link>
                                    <Box className={styles.text_connected}>
                                        <Icon className={styles.icons_connected}>
                                            <img
                                                style={{ width: "100%", height: "100%" }}
                                                src={viewEstate}
                                                onClick={() =>
                                                    (location.href = `${URL_REDIRECT}/${urlFormatted(
                                                        props.currentUser?.wineEntityShortId,
                                                        props.currentUser?.wineEntityName
                                                    )}`)
                                                }
                                            />
                                        </Icon>
                                        <Typography
                                            variant="h5"
                                            style={{
                                                color: theme.palette.primary.main,
                                                lineHeight: "17px",
                                                width: "max-content",
                                            }}
                                            onClick={() =>
                                                (location.href = `${URL_REDIRECT}/${urlFormatted(
                                                    props.currentUser?.wineEntityShortId,
                                                    props.currentUser?.wineEntityName
                                                )}`)
                                            }
                                        >
                                            Voir ma propriété
                                        </Typography>
                                    </Box>
                                    <Box className={styles.text_connected} onClick={handleSignOut}>
                                        <Icon className={styles.icons_connected}>
                                            <img style={{ width: "100%", height: "100%" }} src={logout} />
                                        </Icon>
                                        <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
                                            Déconnexion
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : currentUser?.subscriptionName === SUBSCRIPTION_TYPES.ftReceiver ? (
                                <>
                                    <Box className={styles.text_connected}>
                                        <Icon className={styles.icons_connected}>
                                            <img
                                                style={{ width: "100%", height: "100%" }}
                                                src={ftImage}
                                                onClick={() =>
                                                    (location.href = `${URL_REDIRECT}/dashboard/sheets/inbox`)
                                                }
                                            />
                                        </Icon>
                                        <Typography
                                            variant="h5"
                                            style={{
                                                color: theme.palette.primary.main,
                                                lineHeight: "17px",
                                                width: "max-content",
                                            }}
                                            onClick={() => (location.href = `${URL_REDIRECT}/dashboard/sheets/inbox`)}
                                        >
                                            Fiches techniques reçues
                                        </Typography>
                                    </Box>
                                    <Box className={styles.text_connected} onClick={handleSignOut}>
                                        <Icon className={styles.icons_connected}>
                                            <img style={{ width: "100%", height: "100%" }} src={logout} />
                                        </Icon>
                                        <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
                                            Déconnexion
                                        </Typography>
                                    </Box>
                                </>
                            ) : currentUser?.subscriptionName === SUBSCRIPTION_TYPES.journalist ? (
                                <>
                                    <Box
                                        className={styles.text_connected}
                                        onClick={() => history.push(menuCTA(currentUser, paymentStatus).href)}
                                    >
                                        <Icon className={styles.icons_connected}>
                                            <img style={{ width: "100%", height: "100%" }} src={journalistImage} />
                                        </Icon>
                                        <Typography
                                            variant="h5"
                                            style={{
                                                color: theme.palette.primary.main,
                                                lineHeight: "17px",
                                                width: "max-content",
                                            }}
                                        >
                                            {ti18next("tastingRequest.myTastingRoom")}
                                        </Typography>
                                    </Box>
                                    <Box className={styles.text_connected} onClick={handleSignOut}>
                                        <Icon className={styles.icons_connected}>
                                            <img style={{ width: "100%", height: "100%" }} src={logout} />
                                        </Icon>
                                        <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
                                            {ti18next("common.logout")}
                                        </Typography>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <SubscriptionInProgress />
                                    <Box className={styles.text_connected} onClick={handleSignOut}>
                                        <Icon className={styles.icons_connected}>
                                            <img style={{ width: "100%", height: "100%" }} src={logout} />
                                        </Icon>
                                        <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
                                            Déconnexion
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </Backdrop>
            </>
        );
    }

    return (
        <Backdrop open style={{ zIndex: "1" }}>
            <Box className={styles.container_account} ref={ref}>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {/* if not connected */}
                        <BrowsingPreferenceModal
                            registrationMode={!currentUser}
                            noEnlish={currentUser && currentUser.subscriptionName !== SUBSCRIPTION_TYPES.journalist}
                            open={warningBrowserLanguage}
                            onClickBack={() => {
                                setWarningBrowserLanguage(false);
                                props.onClose();
                            }}
                            onClose={() => {
                                setWarningBrowserLanguage(false);
                                props.onClose();
                            }}
                        />
                        <Box className={styles.container_title}>
                            <Icon className={styles.backIcon} onClick={() => props.onClose()}>
                                <img src={arrow} />
                            </Icon>
                            <Typography variant="h4">{ti18next("connexion.title")}</Typography>
                        </Box>
                        <Box className={styles.form_account} style={{ padding: width < 600 && "12px 7px" }}>
                            {props.openConnectionMenu && (
                                <Typography variant="h5" style={{ fontWeight: 700, marginBottom: "1rem" }}>
                                    Pour ajouter des fiches techniques à votre espace Féret,{" "}
                                    <span style={{ color: theme.palette.primary.main }}>connectez-vous</span>
                                </Typography>
                            )}
                            <ConnectionContainer
                                contactUs={settingsLocale === "en"}
                                marginTop="10px"
                                width={width < 960 ? "100%" : undefined}
                                forgotPasswordOnClick={() => history.push("/bienvenue/mdp")}
                                onSubmit={(formValues) => handleConnectionOnSubmit(formValues)}
                                setCalled={setCalled}
                                called={called}
                                subtitle1
                            />
                            {settingsLocale !== "en" && (
                                <Box className={styles.register_container}>
                                    <hr style={{ marginTop: "3rem" }} />
                                    <Box
                                        className={styles.register}
                                        style={{
                                            flexDirection: width <= 300 && "column",
                                            alignItems: width <= 300 && "center",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        <Typography variant="h5" style={{ marginRight: "4px", fontWeight: 700 }}>
                                            Vous n’avez pas de compte ?
                                        </Typography>
                                        <Link
                                            to={history.addLocale("/bienvenue/inscription")}
                                            style={{
                                                textDecoration: "none",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                                style={{ color: theme.palette.primary.main, fontWeight: 700 }}
                                            >
                                                Inscrivez-vous {props.openConnectionMenu && "gratuitement "}!
                                            </Typography>
                                        </Link>
                                    </Box>
                                    {getSecondPartContent()}
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Backdrop>
    );
});

Account.displayName = "Account";

Account.propTypes = {
    onClose: func,
    currentUser: object,
    openConnectionMenu: bool,
};

export default Account;
