import React, { useState } from "react";
import { bool, object, string } from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import appellationIcon from "assets/icons/propertiesInfosIcons/appellation.svg";
import communeIcon from "assets/icons/propertiesInfosIcons/commune.svg";
import regionIcon from "assets/icons/propertiesInfosIcons/region.svg";
import fioSwipe from "assets/propriétéPage/fio_swipe.svg";
import SummaryElement from "FrontEstate/Components/PropertySummary/SummaryElement";
import SwiperCore, { Pagination } from "swiper";
import certificationIcon from "assets/icons/propertiesInfosIcons/certifications.svg";
import commerceIcon from "assets/icons/propertiesInfosIcons/commerce.svg";
import membreIcon from "assets/icons/propertiesInfosIcons/membre-de.svg";
import vignobleIcon from "assets/icons/propertiesInfosIcons/vignoble.svg";
import ownerIcon from "assets/icons/propertiesInfosIcons/owner.svg";
import zoom from "assets/zoom.svg";
import operatorIcon from "assets/icons/propertiesInfosIcons/operator.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { t, Trans } from "@lingui/macro";
import theme from "globalTheme";
import getConnectors, { commaInFr, firstLetterUppercase, formatConcat, getStrByKey } from "helpers/helpers";
import VineyardDetailsPopin from "FrontEstate/Popins/VineyardDetailsPopin";
import LabelsFrontPopin from "FrontEstate/Popins/LabelsFrontPopin";
import BaseTooltip from "Components/Tooltips/Tooltip";
SwiperCore.use([Pagination]);

const useStyles = makeStyles(() => ({
    swiper_container: {
        borderRadius: "20px",
        background: theme.palette.common.white,
        [theme.breakpoints.up("xl")]: {
            position: "relative",
        },
    },
    fioSwipe: {
        display: "none",
        [theme.breakpoints.up("xl")]: {
            display: "inherit",
            position: "absolute",
            bottom: "-125px",
            right: "-200px",
        },
    },
    swiper: {
        "& .swiper-pagination-bullet": {
            background: theme.palette.primary.main,
        },
    },
}));

const ScrolledPropertySummary = ({ estate, style, locale, isOrphan }) => {
    const styles = useStyles();
    const [vineyardPopinOpen, setVineyardPopinOpen] = useState(false);
    const [labelsPopinOpen, setLabelsPopinOpen] = useState(false);

    const getCertificationsTitle = () => {
        return (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4" className={styles.title}>
                    <Trans>Certifications</Trans>
                </Typography>
                <BaseTooltip fontSize="11px" title={t`Voir plus de détails`} color="primary" arrow>
                    <img src={zoom} className={styles.zoom} onClick={() => setLabelsPopinOpen(true)} />
                </BaseTooltip>
            </Box>
        );
    };

    const getVineyardTitle = () => {
        return (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4" className={styles.title}>
                    <Trans>Vignoble</Trans>
                </Typography>
                <BaseTooltip fontSize="11px" title={t`Voir plus de détails`} color="primary" arrow>
                    <img src={zoom} className={styles.zoom} onClick={() => setVineyardPopinOpen(true)} />
                </BaseTooltip>
            </Box>
        );
    };

    const certifications = () => {
        let certifs = estate?.certifications?.map((item, i) => (
            <Box key={i} className={styles.certificationTypo}>
                {item.name} {item?.isInConversion ? ": " + t`en conversion` : ""}
            </Box>
        ));
        return certifs;
    };

    const wineSurfaceArea = () => {
        const surface = estate?.vineSurfaceArea?.toFixed(2).toString();
        return commaInFr(surface, locale);
    };

    const vineyardGroup = () => {
        return (
            <>
                {estate?.vineSurfaceArea && (
                    <Typography className={styles.certificationTypo}>
                        <Trans>Vignes : {wineSurfaceArea()} ha</Trans>
                    </Typography>
                )}
            </>
        );
    };

    return (
        <Box className={styles.swiper_container} style={style}>
            {vineyardPopinOpen && (
                <VineyardDetailsPopin
                    locale={locale}
                    wineEntityId={estate?.wineEntityId}
                    totalSurfaceArea={estate?.estateSurfaceArea}
                    open={vineyardPopinOpen}
                    onClose={() => setVineyardPopinOpen(false)}
                />
            )}
            {labelsPopinOpen && (
                <LabelsFrontPopin
                    wineEntityId={estate?.wineEntityId}
                    estateName={estate?.name}
                    open={labelsPopinOpen}
                    onClose={() => setLabelsPopinOpen(false)}
                />
            )}
            <img className={styles.fioSwipe} src={fioSwipe} />
            <Swiper
                className={styles.swiper}
                style={{ borderRadius: "20px" }}
                pagination={{ dynamicBullets: true }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    500: {
                        slidesPerView: 1,
                    },
                    600: {
                        slidesPerView: 2,
                    },
                    960: {
                        slidesPerView: 4,
                    },
                }}
            >
                {estate?.region && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "max-content" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start", width: "fit-content" }}
                            icon={regionIcon}
                            title={t`Région`}
                            subtitle={estate?.region}
                        />
                    </SwiperSlide>
                )}

                {estate?.subregion && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "max-content" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start", width: "fit-content" }}
                            icon={estate?.subregionImage?.data}
                            title={t`Sous-région`}
                            subtitle={estate?.subregion}
                        />
                    </SwiperSlide>
                )}
                {estate?.mainAppellation && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "max-content" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start", width: "fit-content" }}
                            icon={appellationIcon}
                            title={t`Appellation`}
                            subtitle={estate?.mainAppellation}
                        />
                    </SwiperSlide>
                )}
                {estate?.commune && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "max-content" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start", width: "fit-content" }}
                            icon={communeIcon}
                            title={t`Commune`}
                            subtitle={estate?.commune}
                            zoomStyle={{ width: "auto" }}
                        />
                    </SwiperSlide>
                )}

                {estate?.owners?.length > 0 && !isOrphan && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "max-content" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start" }}
                            icon={ownerIcon}
                            title={t`Propriétaire`}
                            subtitle={getStrByKey(estate?.owners, "displayName")}
                        />
                    </SwiperSlide>
                )}

                {estate?.operators?.length > 0 && !isOrphan && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "max-content" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start" }}
                            icon={operatorIcon}
                            title={t`Exploitant`}
                            subtitle={getStrByKey(estate?.operators, "displayName")}
                        />
                    </SwiperSlide>
                )}
                {(estate?.vineSurfaceArea !== null || estate?.hasVineyardInformation) && !isOrphan && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "max-content" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start", width: "fit-content" }}
                            icon={vignobleIcon}
                            title={getVineyardTitle()}
                            subtitle={vineyardGroup()}
                        />
                    </SwiperSlide>
                )}
                {estate?.certifications?.length > 0 && !isOrphan && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "max-content" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start", width: "fit-content" }}
                            icon={certificationIcon}
                            title={getCertificationsTitle()}
                            subtitle={certifications()}
                        />
                    </SwiperSlide>
                )}
                {estate?.promotionBodies?.length > 0 && !isOrphan && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "auto", textAlign: "left" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start", width: "fit-content" }}
                            icon={membreIcon}
                            title={t`Membre de`}
                            subtitle={formatConcat(estate?.promotionBodies, ", ")}
                        />
                    </SwiperSlide>
                )}
                {estate?.marketing?.length > 0 && !isOrphan && (
                    <SwiperSlide>
                        <SummaryElement
                            subtitleStyle={{ width: "max-content" }}
                            style={{ justifyContent: "center", margin: "2.8rem" }}
                            typoContainerStyle={{ alignItems: "start", width: "fit-content" }}
                            icon={commerceIcon}
                            title={t`Commercialisation`}
                            subtitle={firstLetterUppercase(getConnectors(estate?.marketing))}
                        />
                    </SwiperSlide>
                )}
            </Swiper>
        </Box>
    );
};

ScrolledPropertySummary.propTypes = {
    estate: object,
    style: object,
    locale: string,
    isOrphan: bool,
};

export default ScrolledPropertySummary;
