import { Box, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Activity } from "assets/searchEngine/home/activity-icon.svg";
import bannerEncyclopedia from "assets/searchEngine/home/Banner-Encyclopedia.png";
import bannerEstates from "assets/searchEngine/home/Banner-Estates.png";
import bannerHistory from "assets/searchEngine/home/Banner-History.png";
import bannerRanking from "assets/searchEngine/home/Banner-Ranking.png";
import bannerWines from "assets/searchEngine/home/Banner-Wines.png";
import bg from "assets/searchEngine/home/bg-search-engine-home.jpg";
import BlayeBourg from "assets/searchEngine/home/Blaye-et-Bourg.png";
import Bordeaux from "assets/searchEngine/home/Bordeaux.png";
import contentHistory from "assets/searchEngine/home/contentHistory.svg";
import darkArrow from "assets/searchEngine/home/dark_arrow.svg";
import EntreDeuxMers from "assets/searchEngine/home/Entre-deux-mers.png";
import { ReactComponent as Estate } from "assets/searchEngine/home/estate-icon.svg";
import GravesSauternes from "assets/searchEngine/home/Graves-et-Sauternes.png";
import wave from "assets/searchEngine/home/home_wave.png";
import Libournais from "assets/searchEngine/home/Libournais.png";
import logoBettaneDesseauve from "assets/searchEngine/home/Logo-Bettane-Desseauve.svg";
import logoFrenchTech from "assets/searchEngine/home/Logo-French-Tech.svg";
import logoRevueDuVin from "assets/searchEngine/home/Logo-revue-du-vin-de-france.svg";
import logoSudOuest from "assets/searchEngine/home/Logo-Sud-Ouest.svg";
import logoUnionGirondine from "assets/searchEngine/home/Logo-Union-Girondine.svg";
import logoVitisphere from "assets/searchEngine/home/Logo-Vitisphere.svg";
import Medoc from "assets/searchEngine/home/Medoc.png";
import overlayRank from "assets/searchEngine/home/overlayRank.svg";
import Waves from "assets/searchEngine/home/Waves.svg";
import whiteArrow from "assets/searchEngine/home/white_arrow.svg";
import { ReactComponent as Wine } from "assets/searchEngine/home/wine-icon.svg";
import arrowHome from "assets/searchEngine/wine/arrow-home.svg";

import imgCarouselSmMillesime from "assets/searchEngine/home/carousel_sm_millesime.webp";
import imgCarouselMdMillesime from "assets/searchEngine/home/carousel_md_millesime.webp";
import imgCarouselSmLettreFeret from "assets/searchEngine/home/carousel_sm_lettre_feret.webp";
import imgCarouselMdLettreFeret from "assets/searchEngine/home/carousel_md_lettre_feret.webp";
import imgCarouselSmVignoble from "assets/searchEngine/home/carousel_sm_vignoble.webp";
import imgCarouselMdVignoble from "assets/searchEngine/home/carousel_md_vignoble.webp";

import imgCarouselBg from "assets/searchEngine/home/carousel_bg.png";
import imgCarouselBgTwo from "assets/searchEngine/home/carousel_bg_two.png";
import imgCarouselArrowRight from "assets/arrow_right.svg";

import imgHeroCardOne from "assets/searchEngine/home/hero_feret.webp";
import imgHeroCardTwo from "assets/searchEngine/home/hero_bordeaux_terroir.webp";
import imgHeroCardThree from "assets/searchEngine/home/hero_tourism.webp";

import { t, Trans } from "@lingui/macro";
import Helmet from "Components/Helmet/Helmet";
import { LoadWrapper } from "Dashboard/Components/LoadWrapper/LoadWrapper";
import { useHomeResultQuery } from "generated/graphql";
import theme from "globalTheme";
import { useWindowDimensions } from "helpers/helpers";
import { useCustomHistory } from "hooks";
import HomeEstateCard from "Search/card/HomeEstateCard";
import HomeWineCard from "Search/card/HomeWineCard";
import LinkCard from "Search/LinkCard";
import SearchCarousel from "Search/SearchCarousel";
import AutocompleteSearch from "./AutocompleteSearch";

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, DotGroup } from 'pure-react-carousel';

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2rem 1rem",
        boxSizing: "border-box",
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundPositionX: "center",
        backgroundSize: "cover",
        paddingBottom: 0,
        marginTop: "-5em",
        paddingTop: "7em",
    },
    main: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        justifyContent: "center",
    },
    mainTitle: {
        gridColumn: "1 / span 12",
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: 13,

        [theme.breakpoints.up("sm")]: {
            marginBottom: 10,
        },

        [theme.breakpoints.up("md")]: {
            marginBottom: 18,
        },

        [theme.breakpoints.up("lg")]: {
            marginBottom: 18,
        },
    },
    search: {
        gridColumn: "3 / span 8",
        justifyContent: "center",
        textAlign: "center",
        marginBottom: 87,
        width: "100%",
        height: 32,

        [theme.breakpoints.up("sm")]: {
            marginBottom: 104,
        },

        [theme.breakpoints.up("xl")]: {
            marginBottom: 144,
        },
    },
    titleSearch: {
        color: theme.palette.primary.light,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: 25,

        [theme.breakpoints.up("sm")]: {
            marginBottom: 37,
        },

        [theme.breakpoints.up("xl")]: {
            marginBottom: 50,
        },
    },
    bloc: {
        padding: "2em 0",
        width: "100%",
        height: "auto",
        textAlign: "center",
    },
    blocEstates: {
        padding: "2em 0",
        width: "100%",
        height: "auto",
        textAlign: "center",
        backgroundImage: `url(${bannerEstates})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundPositionX: "center",
        backgroundSize: "cover",
    },
    blocWines: {
        padding: "2em 0",
        width: "100%",
        height: "auto",
        textAlign: "center",
        backgroundImage: `url(${bannerWines})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundPositionX: "center",
        backgroundSize: "cover",
    },
    blocEncyclopedia: {
        margin: "2em 0",
        padding: "4em 0",
        width: "100%",
        height: "auto",
        textAlign: "center",
        background: `linear-gradient(rgba(145, 88, 104, 0.8), rgba(145, 88, 104, 0.8)), url(${bannerEncyclopedia})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundPositionX: "center",
        backgroundSize: "cover",
        borderRadius: 5,

        [theme.breakpoints.down("xs")]: {
            margin: 0,
            padding: "1em 0",
        },
    },
    blocRanking: {
        margin: "2em 0",
        padding: "4em 0",
        width: "100%",
        height: "auto",
        textAlign: "center",
        background: `linear-gradient(90deg, rgba(0, 0, 0, 0) 0.30%, #360318 57.33%), url(${bannerRanking})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "center",
        backgroundPositionX: "center",
        backgroundSize: "cover",
        borderRadius: 5,
        display: "flex",
        position: "relative",
        justifyContent: "flex-end",

        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0.30%, #360318 57.33%), url(${bannerRanking})`,
        },
    },
    blocHistory: {
        background: `url(${bannerHistory})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "center",
        backgroundPositionX: "center",
        backgroundSize: "cover",

        [theme.breakpoints.down("xs")]: {
            margin: "1em",
            marginTop: "5em",
            borderRadius: "5px",
        },
    },
    contentEncyclopedia: {
        width: "35%",
        margin: "0 auto",

        [theme.breakpoints.down("sm")]: {
            width: "80%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "95%",
        },
    },
    contentRanking: {
        width: "40%",
        marginRight: "8em",

        [theme.breakpoints.down("md")]: {
            marginRight: "4em",
        },
        [theme.breakpoints.down("sm")]: {
            width: "70%",
            marginRight: "0",
            textAlign: "center !important",
        },
        [theme.breakpoints.down("xs")]: {
            width: "85%",
        },
    },
    buttonEncyclopedia: {
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        padding: "0.5em 3em",
        textDecoration: "none",
        color: theme.palette.primary.dark,
        border: `1px solid ${theme.palette.primary.dark}`,
        fontFamily: "lato",

        "&:hover": {
            background: "transparent",
            color: theme.palette.common.white,
            border: `1px solid ${theme.palette.common.white}`,
        },
    },
    blocButtonRanking: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column-reverse",
        },
    },
    buttonRanking: {
        textDecoration: "none",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginLeft: 10,

        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
            margin: "0 1em",
        },
        [theme.breakpoints.down("xs")]: {
            margin: "0",
        },
    },
    buttonRank1: {
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        padding: "0.5em 1.5em",
        textDecoration: "none",
        color: theme.palette.primary.dark,
        border: `1px solid ${theme.palette.common.white}`,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
        "&:hover": {
            background: "transparent",
            border: `1px solid ${theme.palette.common.white}`,
            color: theme.palette.common.white,
        },
    },
    buttonRank2: {
        color: theme.palette.common.white,
        borderRadius: "4px",
        padding: "0.5em 1.5em",
        textDecoration: "none",
        border: `1px solid ${theme.palette.common.white}`,

        [theme.breakpoints.down("xs")]: {
            marginBottom: 20,
            width: "100%",
        },
        "&:hover": {
            background: theme.palette.common.white,
            border: `1px solid ${theme.palette.common.white}`,
            color: theme.palette.primary.dark,
        },
    },
    titleBloc: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: 35,
    },
    titleRegion: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
        textAlign: "left",
        marginBottom: 35,
    },
    titleRanking: {
        color: theme.palette.common.white,
        fontWeight: 400,
        marginBottom: "0.5em",
        textAlign: "right",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
    },
    textRanking: {
        color: theme.palette.common.white,
        fontWeight: 400,
        marginBottom: "1.5em",
        textAlign: "right",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
    },
    separator: {
        gridColumn: "3 / span 8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.common.white,
        fontWeight: 400,
        lineHeight: 0.4,
        marginBottom: 13,

        "&::before": {
            content: "''",
            height: 1,
            width: 200,
            backgroundColor: theme.palette.common.white,
            marginRight: 10,
        },

        "&::after": {
            content: "''",
            height: 1,
            width: 200,
            backgroundColor: theme.palette.common.white,
            marginLeft: 10,
        },

        [theme.breakpoints.up("sm")]: {
            "&::before": {
                width: 200,
            },

            "&::after": {
                width: 200,
            },
        },
    },
    title: {
        gridColumn: "1 / span 12",
        color: theme.palette.common.white,
        fontWeight: 400,
        marginBottom: 23,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
    },
    container_cards: {
        gridColumn: "1 / 13",
        display: "flex",
        gap: "16px 75px",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "2em",
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            justifyContent: "center",
        },
    },
    autocompletePaper: {
        marginTop: 6,
        borderRadius: 4,
        border: "none",
    },
    containerWineRegions: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 20,

        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },

        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
    },
    cardWineRegions: {
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundPositionX: "center",
        backgroundSize: "cover",
        height: "210px",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        position: "relative",

        [theme.breakpoints.down("md")]: {
            height: "170px",
        },
        [theme.breakpoints.down("xs")]: {
            height: "70px",
            // backgroundPositionY: "50%",
            justifyContent: "left",
            paddingLeft: "1.5em",
        },

        "&:hover": {
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        },
    },
    titleWineRegions: {
        cursor: "pointer",
        marginTop: "1.3rem",
        color: theme.palette.primary.dark,

        [theme.breakpoints.down("xs")]: {
            marginTop: "1.5em",
        },
    },
    arrowWineRegions: {
        position: "absolute",
        bottom: 20,
        right: 20,

        [theme.breakpoints.down("xs")]: {
            width: "26px",
            bottom: 7,
            right: 7,
        },
    },
    containerCards: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 20,

        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },

        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
    },
    containerSupport: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 20,

        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(1, 1fr)",
        },

        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(5, 1fr)",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(5, 1fr)",
        },
    },
    containerOrganism: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",

        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },

        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
        [theme.breakpoints.down("sm")]: {
            gap: 20,
        },
        [theme.breakpoints.down("xs")]: {
            gap: 10,
        },
    },
    buttonOrganism: {
        background: theme.palette.common.white,
        padding: "2em 0",
        textDecoration: "none",
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("sm")]: {
            padding: "1em 0",
        },
    },
    textButtonOrganism: {
        borderRight: `1px solid ${theme.palette.primary.dark}`,
        "&:hover": {
            fontWeight: "600",
        },
        [theme.breakpoints.down("sm")]: {
            borderRight: "none",
        },
    },
    contentHistory: {
        maxWidth: "1300px",
        margin: "0 auto",
        padding: "2em 1em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "left !important",

        [theme.breakpoints.down("md")]: {
            maxWidth: "900px",
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
            justifyContent: "center",
            textAlign: "center !important",
            paddingBottom: "4em",
        },
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "1em",
        },
    },
    buttonHistory: {
        backgroundColor: theme.palette.primary.dark,
        padding: "0.5em 3em",
        color: theme.palette.common.white,
        borderRadius: "4px",
        display: "inline-block",
        border: `1px solid ${theme.palette.primary.dark}`,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: "0.75em 0",
            justifyContent: "center",
        },
        "&:hover": {
            background: "transparent",
            color: theme.palette.primary.dark,
            border: `1px solid ${theme.palette.primary.dark}`,
        },
    },
    historyLeft: {
        marginLeft: "8em",

        [theme.breakpoints.down("md")]: {
            marginLeft: "4em",
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0",
        },
    },
    historyRight: {
        [theme.breakpoints.down("md")]: {
            width: "25%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "40%",
            marginTop: "-8em",
            marginBottom: "1em",
        },
    },
    lineSeparator: {
        border: `3px solid ${theme.palette.primary.light}`,
        width: "45px",
        margin: "0 auto",
        marginTop: "0.25em",
    },
    lineSeparatorLeft: {
        border: `3px solid ${theme.palette.primary.light}`,
        width: "45px",
        marginTop: "0.25em",
    },
    lineSeparatorRight: {
        border: `3px solid ${theme.palette.common.white}`,
        width: "45px",
        marginTop: "0.25em",
        marginLeft: "calc(90%)",
        [theme.breakpoints.down("md")]: {
            margin: "0 auto",
        },
    },
    waves: {
        backgroundImage: `url(${Waves})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundPositionX: "center",
        backgroundSize: "cover",
        height: 150,
        width: "100vw",
        [theme.breakpoints.down("lg")]: {
            height: 150,
            marginTop: "0em",
        },
        [theme.breakpoints.down("md")]: {
            height: 85,
            marginTop: "0em",
        },
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    overlayRank: {
        backgroundImage: `url(${overlayRank})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundPositionX: "center",
        backgroundSize: "cover",
        height: 250,
        width: 250,
        position: "absolute",
        left: 0,

        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    fullContent: {
        maxWidth: "1300px",
        margin: "0 auto",
        padding: "2em 1em",

        [theme.breakpoints.down("md")]: {
            padding: "0em 1em",
        },
    },
    buttonSeeMore: {
        "&:hover": {
            fontWeight: "600",
        },
    },
    carousel_provider: { 
        position: "relative", 
        [theme.breakpoints.up("md")]: {
            border: "solid 4px #fff", 
            borderRadius: "8px",
        }
    },
    slider: {
        margin: "-2rem 0 0 0",
        padding: "0 0 2.5em 0",
        [theme.breakpoints.up("md")]: {
            margin: "0",
            backgroundImage: `url(${imgCarouselBg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "100% 100%",
        },
    },
    slide: {
        background: "#FCF6F6",
        maxHeight: "calc(75svh - 60px)",
        [theme.breakpoints.up("md")]: {
            //minHeight: "220px",
            //maxHeight: "220px"
            minHeight: "auto"
        }
    },
    slide_image: {
        objectFit: "cover",
        objectPosition: "top",
        width: "100%",
        height: "100% !important",
        [theme.breakpoints.up("md")]: {
            borderRadius: "8px"
        }
    },
    slide_button: { 
        position: "absolute", 
        top: "50%", 
        transform: "translateY(-50%)",
        border: "0",
        textDecoration: "none",
        padding: "1.5em", 
        borderRadius: "50%",
        background: "#632D3E" 
    },
    slide_button_img: { 
        width: "1.5em", 
        height: "1.5em", 
        position: "absolute", 
        left: "50%", 
        top: "50%", 
        transform: "translate(-50%, -50%)"
    },
    slide_button_img_mirror: {
        transform: "translate(-50%, -50%) rotate(180deg)"
    },
    heroSubCardsContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: 20,
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    heroSubCard: {
        width: "100%",
    },
    heroSubCardImg: {
        borderRadius: "0.25rem",
        width: "100%",
        minHeight: "100%"
    },
    searchContainer: {
        backgroundImage: `url(${imgCarouselBgTwo})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top",
        backgroundPositionX: "center",
        [theme.breakpoints.up("md")]: {
            backgroundSize: "100%"
        },
        backgroundSize: "cover",
    }
}));

const availableLanguages = ["fr", "en"];

const SearchEngineHome = () => {
    const history = useCustomHistory();
    const location = useLocation();
    const locale = useSelector((state) => state?.user?.locale ?? "fr");
    const styles = useStyles();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

    const [searchText, setSearchText] = useState();
    // const choices = [
    //     {
    //         id: 1,
    //         title: t`Une propriété`,
    //         image: function estate(stroke) {
    //             return <Estate style={{ stroke }} />;
    //         },
    //     },
    //     {
    //         id: 2,
    //         title: t`Un vin`,
    //         image: function wine(stroke) {
    //             return <Wine style={{ stroke }} />;
    //         },
    //     },
    //     {
    //         id: 3,
    //         title: t`Une activité`,
    //         image: function activity(stroke) {
    //             return <Activity style={{ stroke }} />;
    //         },
    //     },
    //     // { id: 4, title: t`QR code`, image: (stroke) => <Wine style={{ stroke }} /> },
    // ];
    const { width } = useWindowDimensions();

    const handleSubmit = () => {
        if (searchText) {
            history.push({ pathname: "/recherche/tout/resultats", search: searchText });
        }
    };

    const { data, loading } = useHomeResultQuery();

    let estates = data?.HomeResult?.wineEntityData?.data;
    let wines = data?.HomeResult?.vintageData?.data;
    if (width < 1280) {
        estates = estates?.slice(0, 6);
        wines = wines?.slice(0, 6);
    }

    const wineRegions = [
        { name: "Médoc", link: "/appellations/medoc", banner: Medoc },
        { name: "Blaye et Bourg", link: "/appellations/blaye-et-bourg", banner: BlayeBourg },
        { name: "Libournais", link: "/appellations/libournais", banner: Libournais },
        { name: "Graves et Sauternais", link: "/appellations/graves-et-sauternais", banner: GravesSauternes },
        {
            name: "Bordeaux et Bordeaux supérieur",
            link: "/appellations/bordeaux-et-bordeaux-superieur",
            banner: Bordeaux,
        },
        { name: "Entre-deux-Mers", link: "/appellations/entre-deux-mers", banner: EntreDeuxMers },
    ];

    const supports = [
        {
            src: logoSudOuest,
            alt: "Logo Sud Ouest",
            link: "https://www.sudouest.fr/vin/le-feret-la-reference-de-l-information-viticole-13880192.php",
        },
        { src: logoUnionGirondine, alt: "Logo Union Girondine", link: "https://www.union-girondine.com/ " },
        {
            src: logoBettaneDesseauve,
            alt: "Logo Bettane Desseauve",
            link: "https://www.mybettanedesseauve.fr/2023/02/01/le-feret-deux-siecles-dhistoire-un-pied-dans-le-futur/",
        },
        {
            src: logoVitisphere,
            alt: "Logo Vitisphere",
            link:
                "https://www.vitisphere.com/actualite-98521-le-feret-a-la-relance-comme-reference-numerique-des-vins-de-bordeaux.html",
        },
        {
            src: logoRevueDuVin,
            alt: "Logo Revue du vin",
            link: "https://www.larvf.com/la-personnalite-du-mois-stephane-zittoun-il-a-numerise-le-feret,4813081.asp",
        },
    ];

    return (
        <>
            <Helmet
                title={
                    location.pathname !== history.addLocale("/") && location.pathname !== `/${locale}` && t`Rechercher`
                }
                content={
                    location.pathname !== history.addLocale("/") && location.pathname !== `/${locale}`
                        ? "Effectuez une recherche rapide et facile sur notre site pour trouver toutes les informations dont vous avez besoin sur le monde viticole bordelais. Découvrez les appellations, les domaines, les châteaux, les vins, les événements et bien plus encore. Utilisez notre moteur de recherche performant pour naviguer dans notre encyclopédie en ligne, votre guide complet sur le vignoble bordelais."
                        : "Créez votre compte gratuitement sur notre site et bénéficiez d'un espace sécurisé pour gérer vos informations. Grâce à notre plateforme, créez et diffusez facilement vos fiches techniques pour faire connaître vos vins et votre savoir-faire. Rejoignez la communauté des acteurs passionnés du vignoble bordelais et valorisez votre patrimoine viticole."
                }
                link={
                    <link
                        rel="preload"
                        href={imgCarouselSmLettreFeret}
                        as="image"
                        type="image/webp"
                        imagesizes="100vw"
                    />
                }
            />

            {/* 
            <Box className={styles.root}>
                <Box className={styles.main}>
                    <Typography variant="h1" className={styles.mainTitle}>
                        <Trans>Parcourez le vignoble bordelais</Trans>
                    </Typography>
                    <Box className={styles.search}>
                        <Typography variant="h2" className={styles.titleSearch}>
                            <Trans>Rechercher...</Trans>
                        </Typography>

                        <AutocompleteSearch
                            onChange={(e) => setSearchText(e)}
                            startAdornment
                            inputStyle={{ backgroundColor: theme.palette.common.white, borderRadius: 4, height: 32 }}
                            paperClassname={styles.autocompletePaper}
                            placeholder={t`Saisir la recherche ici...`}
                            onEnterPressed={() => handleSubmit()}
                        />
                    </Box>
                    <Typography variant="h5" className={styles.separator}>
                        <Trans>ou</Trans>
                    </Typography>
                    <Typography variant="h2" className={styles.title}>
                        <Trans>Découvrir...</Trans>
                    </Typography>
                    <Box className={styles.container_cards}>
                        {width > 700 ? (
                            choices?.map((choice) => (
                                <Box key={choice?.id} className={styles.card}>
                                    <LinkCard {...choice} />
                                </Box>
                            ))
                        ) : (
                            <SearchCarousel
                                datas={choices}
                                visibleSlides={1}
                                type="search_home"
                                height={177}
                                width={170}
                            />
                        )}
                    </Box>
                </Box>
                <Box className={styles.waves} />
            </Box> 
            */}

            <Box className={styles.slider}>

                <Box style={{ maxWidth: "1300px", margin: "0 auto", padding: "2em 0 0 0", display: "flex", flexDirection: "column", gap: "2.5em"}}>
                    
                    {/* DESKTOP CAROUSEL */}
                    { matchesMD && (                         
                        <CarouselProvider
                            className={styles.carousel_provider}
                            naturalSlideWidth={5}
                            naturalSlideHeight={1}
                            totalSlides={2}
                            infinite={false}
                            isPlaying={false}
                            interval="4000"
                        >
                            <Slider className={styles.slide}>

                                <Slide index={0} className={styles.slide}>
                                    <Link to={ history.addLocale("/recherche/proprietes/resultats") }>
                                        <Image 
                                            alt="Parcourez le vignoble bordelais"
                                            src={imgCarouselMdVignoble}
                                            hasMasterSpinner={false}
                                            className={styles.slide_image}
                                        />
                                    </Link>
                                </Slide> 

                                <Slide index={1} className={styles.slide}>
                                    <Link to={ history.addLocale("/note-du-millesime") }>
                                        <Image 
                                            alt="Découvrez la notation du Millésime par Féret"
                                            src={imgCarouselMdMillesime}
                                            hasMasterSpinner={false}
                                            className={styles.slide_image}
                                        />
                                    </Link>
                                </Slide> 

                            </Slider>
                            <ButtonBack className={styles.slide_button} style={{ left: "10px" }}>
                                <img src={ imgCarouselArrowRight} className={`${styles.slide_button_img} ${styles.slide_button_img_mirror}`} /> 
                            </ButtonBack>
                            <ButtonNext className={styles.slide_button} style={{ right: "10px" }}>
                                <img src={ imgCarouselArrowRight} className={styles.slide_button_img} /> 
                            </ButtonNext>
                        </CarouselProvider>
                        )
                    }
                
                    {/* MOBILE CAROUSEL */}
                    { matchesSM && (                         
                        <CarouselProvider
                            className={styles.carousel_provider}
                            naturalSlideWidth={1}
                            naturalSlideHeight={1}
                            totalSlides={3}
                            isPlaying={false}
                            infinite={false}
                            interval="4000"
                        >
                            <Slider className={styles.slide}>

                                <Slide index={0} className={styles.slide}>
                                    <Link to={ history.addLocale("/la-lettre-feret-sommaire") }>
                                        <Image 
                                            alt="Découvrez la Lettre Féret"
                                            src={imgCarouselSmLettreFeret}
                                            hasMasterSpinner={false}
                                            className={styles.slide_image}
                                        />
                                    </Link>
                                </Slide>

                                <Slide index={1} className={styles.slide}>
                                    <Link to={ history.addLocale("/recherche/proprietes/resultats") }>
                                        <Image 
                                            alt="Parcourez le vignoble bordelais"
                                            src={imgCarouselSmVignoble}
                                            hasMasterSpinner={false}
                                            className={styles.slide_image}
                                        />
                                    </Link>
                                </Slide>

                                <Slide index={2} className={styles.slide}>
                                    <Link to={ history.addLocale("/note-du-millesime") }>
                                        <Image 
                                            alt="Découvrez la notation du Millésime par Féret"
                                            src={imgCarouselSmMillesime}
                                            hasMasterSpinner={false}
                                            className={styles.slide_image}
                                        />
                                    </Link>
                                </Slide>

                            </Slider>
                            <DotGroup style={{ position: "absolute", bottom: "1em", left: "50%", transform: "translate(-50%, 0)" }}/>
                        </CarouselProvider>
                        
                        )
                    }
                
                    <Box className={styles.heroSubCardsContainer}>

                        <Link className={styles.heroSubCard} to={ history.addLocale("/la-lettre-feret-sommaire") } >
                            <img className={styles.heroSubCardImg} src={ imgHeroCardOne } alt=""></img>
                        </Link>

                        <Link className={styles.heroSubCard} to={ history.addLocale("/encyclopedie-feret/article/bordeaux-et-son-terroir") }>
                            <img className={styles.heroSubCardImg} src={ imgHeroCardTwo } alt=""></img>
                        </Link>

                        <Link to={ history.addLocale("/recherche/activites/resultats") } className={styles.heroSubCard}>
                            <img className={styles.heroSubCardImg} src={ imgHeroCardThree } alt=""></img>
                        </Link>

                    </Box>
                </Box>

            </Box>
            
            {/* <Box className={ styles.searchContainer }>
                <Box style={{ maxWidth: "1100px", margin: "0 auto", padding: "100px 0" }}>

                    <Typography variant="h2" className={styles.titleSearch}><b><Trans>Parcourez le vignoble bordelais</Trans></b></Typography>

                    <Box className={styles.search}>
                        <Typography variant="h2" className={styles.titleSearch} style={{ fontSize: "28px" }}>
                            <Trans>Rechercher...</Trans>
                        </Typography>

                        <AutocompleteSearch
                            onChange={(e) => setSearchText(e)}
                            startAdornment
                            inputStyle={{ backgroundColor: "#E8D4DD", borderRadius: "30px", height: "100%", padding: "1em", maxWidth: "300px", fontStyle: "normal", color: "#9F8D93", "&::placeholder": { color: "#9F8D93" } }}
                            paperClassname={styles.autocompletePaper}
                            placeholder={t`Saisir la recherche ici...`}
                            onEnterPressed={() => handleSubmit()}
                        />
                    </Box>

                </Box>
            </Box> */}

            <Box style={{ backgroundColor: "#FFEFF6" }}>
                <Box className={styles.fullContent}>
                    {availableLanguages.includes(locale) && (
                        <Box className={styles.bloc}>
                            <Typography variant="h3" className={styles.titleRegion}>
                                <Trans>Les régions viticoles du vignoble bordelais</Trans>
                                <Box className={styles.lineSeparatorLeft}></Box>
                            </Typography>
                            <Box className={styles.containerWineRegions}>
                                {wineRegions?.map((wineRegion, i) => (
                                    <Link
                                        key={i}
                                        style={{ textDecoration: "none" }}
                                        to={history.addLocale(wineRegion.link)}
                                    >
                                        <Box
                                            className={styles.cardWineRegions}
                                            style={{
                                                backgroundImage:
                                                    width >= 600
                                                        ? `url(${wave}),url(${wineRegion.banner})`
                                                        : `linear-gradient(to bottom, rgba(252, 246, 246, 0.8), rgba(252, 246, 246, 0.8)), url(${wineRegion.banner})`,
                                            }}
                                        >
                                            <Typography variant="h4" className={styles.titleWineRegions}>
                                                {wineRegion.name}
                                            </Typography>
                                            <img
                                                src={width >= 600 ? whiteArrow : darkArrow}
                                                alt="arrow wine region"
                                                className={styles.arrowWineRegions}
                                            />
                                        </Box>
                                    </Link>
                                ))}
                            </Box>
                        </Box>
                    )}

                    <Box className={styles.blocEstates}>
                        <Typography variant="h3" className={styles.titleBloc}>
                            <Trans>Quelques propriétés à (re)découvrir</Trans>
                            <Box className={styles.lineSeparator}></Box>
                        </Typography>
                        <LoadWrapper loading={loading}>
                            <Box className={styles.containerCards}>
                                {width >= 600 ? (
                                    estates?.map((estate, i) => (
                                        <HomeEstateCard
                                            estate={estate}
                                            key={i}
                                            name={estate?.wineEntityName}
                                            wineEntityId={estate?.wineEntityId}
                                            appellation={estate?.appellation}
                                        />
                                    ))
                                ) : (
                                    <SearchCarousel
                                        datas={estates}
                                        visibleSlides={1}
                                        type="estates_home"
                                        height={177}
                                        width={300}
                                    />
                                )}
                            </Box>
                        </LoadWrapper>
                        <Link
                            to={history.addLocale("/recherche/proprietes/resultats")}
                            style={{ textDecorationColor: theme.palette.primary.dark }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "2em",
                                }}
                            >
                                <img src={arrowHome} alt="arrow home" style={{ marginRight: "1em" }} />
                                <Typography
                                    variant="body2"
                                    style={{ color: theme.palette.primary.dark, alignItems: "center" }}
                                    className={styles.buttonSeeMore}
                                >
                                    <Trans>Voir toutes les propriétés</Trans>
                                </Typography>
                            </Box>
                        </Link>
                    </Box>

                    {availableLanguages.includes(locale) && (
                        <Box className={styles.blocEncyclopedia}>
                            <Box className={styles.contentEncyclopedia}>
                                <Typography
                                    variant="h3"
                                    style={{ color: theme.palette.common.white, marginBottom: "0.5em" }}
                                >
                                    <Trans>L’encylopédie Féret</Trans>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{ color: theme.palette.common.white, marginBottom: "1.5em" }}
                                >
                                    <Trans>
                                        <i>Bordeaux et ses Vins</i> est l’encyclopédie la plus complète au monde sur le
                                        vignoble bordelais. Découvrez les articles de nos experts autour de la vigne et du
                                        vin en Gironde.
                                    </Trans>
                                </Typography>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    to={history.addLocale("/encyclopedie-feret")}
                                >
                                    <Typography variant="body2" className={styles.buttonEncyclopedia}>
                                        <Trans>Consulter l’encyclopédie Féret</Trans>
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                    )}

                    <Box className={styles.blocWines}>
                        <Typography variant="h3" className={styles.titleBloc}>
                            <Trans>(Re)découvrez ces vins</Trans>
                            <Box className={styles.lineSeparator}></Box>
                        </Typography>
                        <LoadWrapper loading={loading}>
                            <Box className={styles.containerCards}>
                                {width >= 600 ? (
                                    wines?.map((wine) => (
                                        <HomeWineCard
                                            wine={wine}
                                            sweetness={wine?.sweetness}
                                            wineId={wine?._id}
                                            key={wine?._id}
                                            name={wine?.brandName}
                                            color={wine?.color}
                                            appellation={wine?.appellation}
                                            wineEntityName={wine?.wineEntityName}
                                            wineEntityId={wine?.wineEntityId}
                                            vintageIds={wine?.vintageIds}
                                        />
                                    ))
                                ) : (
                                    <SearchCarousel
                                        datas={wines}
                                        visibleSlides={1}
                                        type="wines_home"
                                        height={250}
                                        width={170}
                                    />
                                )}
                            </Box>
                        </LoadWrapper>
                        <Link
                            to={history.addLocale("/recherche/vins/resultats")}
                            style={{ textDecorationColor: theme.palette.primary.dark }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "1em",
                                }}
                            >
                                <img src={arrowHome} alt="Arrow Home" style={{ marginRight: "1em" }} />
                                <Typography
                                    variant="body2"
                                    style={{ color: theme.palette.primary.dark, alignItems: "center" }}
                                    className={styles.buttonSeeMore}
                                >
                                    <Trans>Voir toutes les vins</Trans>
                                </Typography>
                            </Box>
                        </Link>
                    </Box>

                    {availableLanguages.includes(locale) && (
                        <Box className={styles.blocRanking}>
                            <Box className={styles.overlayRank} />
                            <Box className={styles.contentRanking}>
                                <Typography variant="h3" className={styles.titleRanking}>
                                    <Trans>Les 5 classements de Bordeaux</Trans>
                                    <Box className={styles.lineSeparatorRight}></Box>
                                </Typography>
                                <Typography variant="body1" className={styles.textRanking}>
                                    <Trans>
                                        Découvrez les classements des vins du vignoble bordelais, largement basés sur la
                                        notion de terroir, ils tiennent aussi compte de l’histoire, des moyens et techniques
                                        mis en œuvre, de la qualité du vignoble...
                                    </Trans>
                                </Typography>
                                <Box className={styles.blocButtonRanking}>
                                    <Link
                                        className={styles.buttonRanking}
                                        to={history.addLocale("/les-classements-de-bordeaux")}
                                    >
                                        <Typography variant="body2" className={styles.buttonRank1}>
                                            <Trans>Découvrir les classements</Trans>
                                        </Typography>
                                    </Link>
                                    <Link
                                        className={styles.buttonRanking}
                                        to={history.addLocale(
                                            "/encyclopedie-feret/article/les-classements-des-vins-de-bordeaux?page=1"
                                        )}
                                    >
                                        <Typography variant="body2" className={styles.buttonRank2}>
                                            <Trans>Lire l&apos;article</Trans>
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {availableLanguages.includes(locale) && (
                        <Box className={styles.bloc}>
                            <Typography variant="h3" className={styles.titleBloc}>
                                <Trans>Les organismes du vignobles bordelais</Trans>
                                <Box className={styles.lineSeparator}></Box>
                            </Typography>
                            <Box className={styles.containerOrganism}>
                                <Link
                                    className={styles.buttonOrganism}
                                    to={history.addLocale("/organismes-vignoble-bordelais/ODG")}
                                >
                                    <Typography variant="h5" className={styles.textButtonOrganism}>
                                        <Trans>DÉFENSE ET GESTION</Trans>
                                    </Typography>
                                </Link>
                                <Link
                                    className={styles.buttonOrganism}
                                    to={history.addLocale("/organismes-vignoble-bordelais/formation")}
                                >
                                    <Typography variant="h5" className={styles.textButtonOrganism}>
                                        <Trans>FORMATION</Trans>
                                    </Typography>
                                </Link>
                                <Link
                                    className={styles.buttonOrganism}
                                    to={history.addLocale("/organismes-vignoble-bordelais/representation")}
                                >
                                    <Typography variant="h5" className={styles.textButtonOrganism}>
                                        <Trans>REPRÉSENTATION</Trans>
                                    </Typography>
                                </Link>
                                <Link
                                    className={styles.buttonOrganism}
                                    to={history.addLocale("/organismes-vignoble-bordelais/concours")}
                                >
                                    <Typography
                                        variant="h5"
                                        className={styles.textButtonOrganism}
                                        style={{ borderRight: "none" }}
                                    >
                                        <Trans>CONCOURS</Trans>
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>

            {availableLanguages.includes(locale) && (
                <Box className={styles.blocHistory}>
                    <Box className={styles.contentHistory}>
                        <Box className={styles.historyLeft}>
                            <Typography variant="h3" style={{ color: theme.palette.primary.dark }}>
                                <Trans>Profitez de l’histoire bicentenaire de Féret</Trans>
                            </Typography>
                            <Typography variant="body1" style={{ color: theme.palette.primary.dark }}>
                                <Trans>
                                    Vous représentez une propriété ?{" "}
                                    <b>
                                        Vous aussi, figurez sur <i>Bordeaux et ses Vins</i> !
                                    </b>
                                </Trans>
                            </Typography>
                            <Box style={{ marginTop: 20 }}>
                                <Link
                                    style={{ textDecoration: "none" }}
                                    to={history.addLocale("/figurer-dans-bordeaux-et-ses-vins")}
                                >
                                    <Typography variant="body2" className={styles.buttonHistory}>
                                        <Trans>
                                            Commencer l&apos;aventure <i>Bordeaux et ses Vins</i>
                                        </Trans>
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                        <img src={contentHistory} alt="content history" className={styles.historyRight} />
                    </Box>
                </Box>
            )}

            {availableLanguages.includes(locale) && (
                <Box style={{ maxWidth: "1300px", margin: "0 auto", padding: "2em 1em", paddingTop: 0 }}>
                    <Box className={styles.bloc}>
                        <Typography variant="h3" className={styles.titleBloc}>
                            <Trans>Ils parlent de nous</Trans>
                            <Box className={styles.lineSeparator}></Box>
                        </Typography>
                        <Box className={styles.containerSupport}>
                            {width >= 960 ? (
                                supports?.map((support, i) => (
                                    <Link key={i} to={{ pathname: support.link }} target="_blank">
                                        <img src={support.src} alt={support.alt} />
                                    </Link>
                                ))
                            ) : (
                                <SearchCarousel
                                    datas={supports}
                                    visibleSlides={2}
                                    type="supports_home"
                                    height={150}
                                    width={150}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default SearchEngineHome;
