import { string } from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import theme from "globalTheme";
import { firstLetterUppercase, useWindowDimensions } from "helpers/helpers";

const useStyles = makeStyles(() => ({
    card: {
        minHeight: "100px",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "6px",
        justifyContent: "start",
        alignItems: "center",
        gap: "12px",
        textAlign: "center",
        backgroundColor: theme.palette.common.white,
    },
    icon: {
        height: "53px",
        opacity: "0.6",
    },
}));

const OptionCard = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    return (
        <Box {...props} style={{ display: "flex", flexDirection: "column", gap: "9px" }}>
            {width >= 960 && <img src={props.icon} className={styles.icon} />}
            <Box {...props} className={`${styles.card} `}>
                <Typography variant="h4" style={{ fontWeight: 400 }}>
                    « {firstLetterUppercase(props.subscriptionName)} »{" "}
                </Typography>
                <Typography variant="h6" style={{ fontStyle: "italic" }}>
                    {props.description}
                </Typography>
                <Typography variant="h6" style={{ fontStyle: "italic" }}>
                    {props.publication}
                </Typography>
            </Box>
        </Box>
    );
};

OptionCard.propTypes = {
    subscriptionName: string,
    description: string,
    publication: string,
    icon: string,
};

export default OptionCard;
